<template>
    
    <Section :options="sectionOptions">

        <div class="notes access-restricted">
            <h2 v-if="labels.title">{{ $cms.utils.getComputedLabel(labels.title) }}</h2>
            <p v-if="labels.text">{{ $cms.utils.getComputedLabel(labels.text) }}</p>
        </div>

    </Section>

</template>

<style lang="scss">

.notes.access-restricted {
    min-height:50vh;
    max-width:800px;
    margin-left:auto;
    margin-right:auto;
}

.notes h2 {
  margin-top:20px;
  text-align: center;
  margin-bottom:0px;
}

.notes p {
  text-align: center;
}

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';

/** @cmsOptions */
export interface AccessRestrictedOptions {
    /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions
}

/** @cmsLabels */
export interface AccessRestrictedLabels {
  /** @cmsType CmsLabel */
  title?:CmsLabel;
  /** @cmsType CmsContent */
  text?:CmsContent;
}

/** @cmsSlots */
export interface AccessRestrictedSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<AccessRestrictedOptions>,
        labels: {
          type: Object as PropType<AccessRestrictedLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<AccessRestrictedSlots>
    },
    components: {
        Section
    },
    setup(props, context) {
        const app = getApp();

        const computedOptions:AccessRestrictedOptions = {
            sectionOptions : {
                
            },
            ...props.options
        };

        const labels:AccessRestrictedLabels = {
            title: app.$t('authentication.access-restricted.title'),
            text: app.$t('authentication.access-restricted.text'),
            ...props.labels
        }

        return {
            sectionOptions:computedOptions.sectionOptions,
            labels
        }

    }
})
</script>